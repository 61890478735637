<template>
  <div class="is-quiz-answer">
    <div class="begin mb-4">
      <div
        class="is-ans bg-white rounded-lg p-6 cursor-pointer"
        @click="openAnswer"
      >
        <div class="quest flex items-center justify-between">
          <div class="quest-name text-black mr-12">
            {{ answersLists.label }}
          </div>
          <div class="flex items-center">
            <div class="flex items-center mr-4">
              <div class="update mr-4" @click.stop="updateQuestion">
                <svg-icon
                  name="ic_update"
                  original
                  class="w-4 h-4"
                />
              </div>
              <div class="delete" @click.stop="$emit('openConfirmation', answersLists.id)">
                <svg-icon
                  name="ic_delete-red"
                  original
                  class="w-4 h-4"
                />
              </div>
            </div>
            <div class="icons">
              <svg-icon
                v-if="!answer"
                name="ic_down"
                original
                class="w-4 h-4"
              />
              <svg-icon
                v-if="answer"
                name="ic_up"
                original
                class="w-4 h-4"
              />
            </div>
          </div>
        </div>
        <div v-if="answer" class="responses mt-4 cursor-default">
          <div
            v-for="(item, i) in allProposition"
            :key="i"
            class="right-ans flex items-center mb-4"
          >
            <div class="icon mr-4">
              <svg-icon
                v-if="item.isValid === true"
                name="ic_select-right-answer"
                original
                class="w-5 h-5"
              />
              <svg-icon
                v-if="item.isValid === false"
                name="ic_select-wrong-answer"
                original
                class="w-5 h-5"
              />
            </div>
            <div class="ans-title text-black">{{ item.label }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import http from '../../../plugins/http'
import apiRoute from '../../../router/api-routes'
export default {
  name: 'is-quiz-answer',
  props: {
    answersLists: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  data () {
    return {
      answer: false,
      allProposition: []
    }
  },
  created () {
    this.findPropositionByQuestion()
  },
  methods: {
    updateQuestion () {
      const data = {
        question: this.answersLists,
        proposition: this.allProposition
      }
      console.log('data', data)
      this.$emit('sendQuestionData', data)
    },
    findPropositionByQuestion () { // GET ALL ANSWER PROPOSITION BY QUESTION ID
      http.post(apiRoute.baseURL + apiRoute.getPropositionByQuestion, {
        questionId: this.answersLists.id
      }, {
        headers: {
          Authorization: `Bearer ${this.$store.getters.loginToken}`
        }
      }).then(response => {
        // console.log(response)
        this.allProposition = response
        this.allProposition.sort(function (x, y) {
          const a = new Date(x.createdAt)
          const b = new Date(y.createdAt)
          return a - b
        })
      }).catch(error => {
        console.log(error)
      })
    },
    openAnswer () {
      this.answer = !this.answer
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "../../../assets/style/sass/variables";
  .is-ans {
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.15);
  }
</style>
