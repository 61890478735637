<template>
  <div class="is-quiz pb-24">
    <div class="begin">
      <div class="back">
        <svg-icon
          name="ic_go-back"
          original
          class="w-8 h-8 cursor-pointer"
          @click="goBack"
        />
      </div><br>
      <div class="cont bg-white rounded py-10 px-10">
        <div class="loading" :class="{ 'not_empty': isLoading === false }">
          <loading
            :active="isLoading"
            :can-cancel="true"
            :on-cancel="onCancel"
            :is-full-page="fullPage"
            loader="dots"
            color="#0B5382"
            background-color="transparent"
          />
        </div>
        <div v-if="nothing && !isLoading" class="nothing text-center py-8 text-xl">
          {{ $t('noQuizAdded') }}
        </div>
        <div v-if="!isLoading">
          <div class="categories-lists flex items-center justify-between flex-wrap w-full">
            <quiz-list
              v-for="(item, i) in quizz"
              :key="i"
              :quiz-list="item"
              class="w-1/2 mr-8"
              @click.native="openQuiz(i)"
              @openConfirmation="openConfirmation"
            />
          </div>
        </div>
      </div>
    </div>

    <confirm-delete-quiz v-if="isConfirm" :quiz-id="quizId" @goBack="closeConfirmation" @openSuccessDelete="openSuccessDelete"></confirm-delete-quiz>

    <success-add v-if="isSuccess" message="Quiz supprimé avec succès" button-label="Retour" @closeSuccess="closeSuccess"></success-add>
  </div>
</template>

<script>
import QuizList from './quiz-list'
import http from '../../../plugins/http'
import apiRoute from '../../../router/api-routes'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import ConfirmDeleteQuiz from '@/components/helper/quiz/confirm-delete-quiz.vue'
import SuccessAdd from '@/components/helper/success-add.vue'
export default {
  name: 'is-quiz',
  components: { SuccessAdd, ConfirmDeleteQuiz, Loading, QuizList },
  props: {
    quizCategoryInfo: Object
  },
  data () {
    return {
      isLoading: true,
      fullPage: false,
      nothing: true,
      quizz: [],
      isConfirm: false,
      quizId: null,
      isSuccess: false
    }
  },
  created () {
    this.getAllQuiz()
  },
  methods: {
    openSuccessDelete (value) {
      this.isSuccess = value
      this.isConfirm = false
    },
    closeSuccess (value) {
      this.isSuccess = value
      this.getAllQuiz()
    },
    openConfirmation (value) {
      this.quizId = value
      this.isConfirm = true
    },
    closeConfirmation (value) {
      this.isConfirm = value
    },
    getAllQuiz () { // GET ALL QUIZ LIST
      http.post(apiRoute.baseURL + apiRoute.getQuizByCategory, {
        categorieId: this.quizCategoryInfo.id
      }, {
        headers: {
          Authorization: `Bearer ${this.$store.getters.loginToken}`
        }
      }).then(response => {
        // console.log(response)
        this.quizz = response
        if (this.quizz.length === 0) {
          this.isLoading = false
          this.nothing = true
        } else {
          this.isLoading = false
          this.nothing = false
        }
      }).catch(error => {
        console.log(error)
      })
    },
    openQuiz (index) { // OPEN QUIZ DETAILS
      this.$emit('updateQuizInfo', this.quizz[index])
      this.$emit('updateQuiz', true)
    },
    goBack () { // GO BACK TO CATEGORY LIST
      this.$emit('closeQuizList', false)
    },
    onCancel () {
      console.log('User cancelled the loader.')
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "../../../assets/style/sass/variables";
  .cont {
    min-height: 70vh;
  }
  .loading {
    position: relative;
    height: 10vh;
  }
  .not_empty {
    height: auto;
  }
  .quiz-list {
    width: 48%;
    &:nth-child(2n) {
      margin-right: 0;
    }
  }
</style>
