<template>
  <div class="create-quiz">
    <div v-if="!waitLoader" class="begin">
      <div class="back">
        <svg-icon
          name="ic_go-back"
          original
          class="w-8 cursor-pointer"
          @click="goBack"
        />
      </div><br>
      <div class="body bg-white rounded flex">
        <div class="part-1 w-3/4 p-8">
          <div class="login-form">
            <label>
              <input
                v-model="title"
                type="text"
                :placeholder="$t('quizTitle') + '*'"
                class="ipt p-4 w-full border rounded-lg text-black font-medium"
              >
            </label>
          </div><br>
          <div v-if="$store.getters.isNewQuiz === false && !waitLoader" class="add-quest-button p-6">
            <button-base
              :label="$t('addQuestionButton')"
              background="#E6E6E6"
              fontcolor="#000000"
              @click.native="openAddNewQuestion"
            />
          </div>
          <div v-if="showAddQuestion" class="quest-bloc px-6">
            <is-quiz-question
              :quiz-id="quizId"
              :question-data="questionData"
              @showAddQuizSuccess="showAddQuizSuccess"
              @closeUpdateQuestion="closeUpdateQuestion"
              @deletePropositionSuccess="deletePropositionSuccess"
            />
          </div>
          <div v-if="$store.getters.isNewQuiz === false && !waitLoader" class="answer px-6">
            <is-quiz-answer
              v-for="(item, i) in answers"
              :key="i"
              :answers-lists="item"
              @openConfirmation="openConfirmation"
              @sendQuestionData="sendQuestionData"
            />
          </div><br>
        </div>
        <div class="part-2 w-1/4 p-8">
          <div class="login-form">
            <div class="label mb-2">{{ $t('category') }}*</div>
            <div class="input relative">
              <label>
                <select
                  v-model="category"
                  class="ipt rounded-lg w-full py-4 pl-4 pr-12 border appearance-none bg-white"
                >
                  <option
                    v-for="(item, i) in allCategories"
                    :key="i"
                    :value="item.id"
                  >
                    {{ item.label }}
                  </option>
                </select>
              </label>
              <div class="absolute top-0 right-0 p-4">
                <svg-icon
                  name="ic_down"
                  original
                  class="w-4 h-4"
                />
              </div>
            </div>
          </div><br>
          <div class="login-form relative">
            <div class="label mb-3">{{ $t('quizPicture') }}* (Moins de 1Mo)</div>
            <image-uploader
              :preview="true"
              :className="['fileinput', { 'fileinput--loaded': hasImage }]"
              :quality="0.9"
              :scaleRatio="1"
              capture="environment"
              accept="image/*"
              :debug="1"
              doNotResize="gif"
              :autoRotate="true"
              outputFormat="verbose"
              @input="setImage"
            >
              <label for="fileInput" slot="upload-label">
                <div v-if="!hasImage" class="input">
                  <div class="ipt py-8 w-full border rounded-lg cursor-pointer">
                    <div class="icones mb-3">
                      <svg-icon
                        name="ic_add-image"
                        original
                        class="w-8 h-8 m-auto"
                      />
                    </div>
                    <div class="add text-center">{{ $t('selectionImage') }}</div>
                  </div>
                </div>
                <div v-if="hasImage" class="close-pic absolute cursor-pointer text-red-400 py-1 px-2 rounded-full bg-white w-fit shadow">+</div>
              </label>
            </image-uploader>
          </div><br>
          <div v-if="error" class="error text-red-600">
            {{ errorMessage }}
          </div><br>
          <div v-if="$store.getters.isNewQuiz === true" class="login-button mt-4">
            <button-base
              :label="$t('addButton')"
              :is-loading="loader"
              @click.native="createNewQuiz"
            />
          </div>
          <div v-else class="login-button mt-4">
            <button-base
              label="Modifier quiz"
              :is-loading="loader"
              @click.native="updateOneQuiz"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- LOADER FOR WAITING SAVE -->
    <div class="loading" :class="{ 'not_empty': waitLoader === false }">
      <loading
        :active="waitLoader"
        :can-cancel="true"
        :on-cancel="onCancel"
        :is-full-page="fullPage"
        loader="dots"
        color="#0B5382"
        background-color="transparent"
      />
    </div>
    <!-- SUCCESS ADD POPUP -->
    <success-add
      v-if="successAdd"
      :message="successMessage"
      :button-label="successButton"
      @closeSuccess="closeSuccess"
    />

    <confirm-delete-question :question-id="questionId" v-if="isConfirm" @goBack="goBackConfirmation" @openSuccessDelete="openSuccessDelete" />
  </div>
</template>

<script>
import ButtonBase from '../add/button-base'
import http from '../../../plugins/http'
import apiRoute from '../../../router/api-routes'
import IsQuizAnswer from './is-quiz-answer'
import IsQuizQuestion from './is-quiz-question'
import SuccessAdd from '../success-add'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import ConfirmDeleteQuestion from '@/components/helper/quiz/confirm-delete-question.vue'
export default {
  name: 'create-quiz',
  components: { ConfirmDeleteQuestion, SuccessAdd, IsQuizQuestion, IsQuizAnswer, ButtonBase, Loading },
  props: {
    isQuizInfo: Object
  },
  data () {
    return {
      successAdd: false,
      title: '',
      category: null,
      pictureId: null,
      allCategories: [],
      showAddQuestion: false,
      loader: false,
      error: false,
      errorMessage: '',
      quizId: null,
      answers: [],
      hasImage: false,
      image: null,
      waitLoader: false,
      fullPage: true,
      successMessage: '',
      successButton: '',
      isConfirm: false,
      questionId: null,
      questionData: null
    }
  },
  created () {
    this.getCategories()
    this.findQuestionByQuiz()
    console.log(this.isQuizInfo)
  },
  mounted () {
    if (this.isQuizInfo) {
      this.quizId = this.isQuizInfo.quiz.id
      this.title = this.isQuizInfo.quiz.label
      this.category = this.isQuizInfo.quiz.categorieId
      this.pictureId = this.isQuizInfo.quiz.pictureId
    }
  },
  methods: {
    deletePropositionSuccess () {
      // this.findQuestionByQuiz()
    },
    closeUpdateQuestion (value) {
      this.showAddQuestion = value
    },
    sendQuestionData (value) {
      this.showAddQuestion = false
      this.questionData = null
      console.log(this.questionData)
      this.questionData = value
      console.log('value', this.questionData)
      this.showAddQuestion = true
    },
    openSuccessDelete (value) {
      this.successAdd = value
      this.isConfirm = false
      this.successMessage = 'Question supprimée avec succès'
      this.successButton = this.$t('goBackAddMemberButton')
    },
    openConfirmation (value) {
      this.questionId = value
      this.isConfirm = true
    },
    goBackConfirmation (value) {
      this.isConfirm = value
    },
    showAddQuizSuccess (value) { // OPEN SUCCESS POPUP FROM ADD NEW QUESTION
      this.successAdd = value
      this.successMessage = this.$t('newQuestionSuccess')
      this.successButton = this.$t('goBackAddMemberButton')
    },
    setImage: function (output) { // FUNCTION TO COMPRESS IMAGE
      this.hasImage = true
      this.image = output
      console.log('Info', output.info)
      console.log('Exif', output.exif)
      // this.createFileId(this.image.dataUrl)
    },
    findQuestionByQuiz () { // GET ALL QUESTION BY QUIZ ID
      http.post(apiRoute.baseURL + apiRoute.getQuestionByQuiz, {
        quizId: this.isQuizInfo.quiz.id
      }, {
        headers: {
          Authorization: `Bearer ${this.$store.getters.loginToken}`
        }
      }).then(response => {
        // console.log(response)
        this.answers = response
        this.answers.sort(function (x, y) {
          return x.order - y.order
        })
        this.$store.dispatch('CREATE_ORDER', this.answers.length)
      }).catch(error => {
        console.log(error)
      })
    },
    async updateOneQuiz () {
      console.log('update')
      this.waitLoader = true
      const picture = this.image ? await this.createFileId(this.image.dataUrl) : this.pictureId
      console.log(picture)
      await http.patch(apiRoute.baseURL + apiRoute.updateQuiz, {
        id: this.isQuizInfo.quiz.id,
        label: this.title,
        pictureId: picture,
        categorieId: this.category
      }, {
        headers: {
          Authorization: `Bearer ${this.$store.getters.loginToken}`
        }
      }).then(response => {
        // console.log(response)
        // this.quizId = response.id
        this.successAdd = true
        this.successMessage = 'Quiz modifié avec succès'
        this.successButton = this.$t('goBackAddMemberButton')
        this.waitLoader = false
        // this.$store.dispatch('CREATE_NEW_QUIZ', false)
        // this.$store.dispatch('CREATE_ORDER', 0)
      }).catch(error => {
        console.log(error)
      })
    },
    async createNewQuiz () { // CREATE A NEW QUIZ
      if (this.title === '' || this.category === null || this.image === null) {
        this.error = true
        this.errorMessage = this.$t('errorAddAdmin')
      } else {
        this.error = false
        this.waitLoader = true

        await this.createFileId(this.image.dataUrl)
        await http.post(apiRoute.baseURL + apiRoute.createQuiz, {
          label: this.title,
          pictureId: this.pictureId,
          categorieId: this.category
        }, {
          headers: {
            Authorization: `Bearer ${this.$store.getters.loginToken}`
          }
        }).then(response => {
          // console.log(response)
          this.quizId = response.id
          this.successAdd = true
          this.successMessage = this.$t('newQuizSuccess')
          this.successButton = this.$t('goBackAddMemberButton')
          this.waitLoader = false
          this.$store.dispatch('CREATE_NEW_QUIZ', false)
          this.$store.dispatch('CREATE_ORDER', 0)
        }).catch(error => {
          console.log(error)
        })
      }
    },
    closeSuccess (val) { // CLOSE SUCCESS POPUP
      this.successAdd = val
      this.waitLoader = true
      /*  if (this.answers.length > 0) {
        this.findQuestionByQuiz()
      } */
      setTimeout(() => {
        this.waitLoader = false
        this.findQuestionByQuiz()
      }, 1000)
    },
    openAddNewQuestion () { // OPEN ADD NEW QUESTION
      if (this.$store.getters.isNewQuiz === false) {
        this.showAddQuestion = true
      } else {
        this.error = true
        this.errorMessage = this.$t('addQuizFirstError')
      }
    },
    getCategories () { // GET ALL QUIZ CATEGORY
      http.post(apiRoute.baseURL + apiRoute.getCategoryByType, {
        type: 'QUIZ'
      }, {
        headers: {
          Authorization: `Bearer ${this.$store.getters.loginToken}`
        }
      })
        .then(response => {
          // console.log(response)
          this.allCategories = response
        }).catch(error => {
          console.log(error)
        })
    },
    createFileId (filename) { // SEND IMAGE TO GET ID
      return new Promise((resolve, reject) => {
        http.post(apiRoute.baseURL + apiRoute.createFile, {
          file: filename
        }, {
          headers: {
            Authorization: `Bearer ${this.$store.getters.loginToken}`
          }
        }).then(response => {
          console.log(response)
          this.pictureId = response.id
          resolve(this.pictureId)
        }).catch(error => {
          console.log(error)
        })
      })
    },
    goBack () { // GO BACK TO INDEX
      this.$emit('closeCreateQuiz', false)
    },
    onCancel () {
      console.log('User cancelled the loader.')
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "../../../assets/style/sass/variables";
  .body {
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.08);
    min-height: 50vh;
  }
  .part-1 {
    background: #F3F5F7;
  }
  .close-pic {
    width: fit-content;
    top: 15px;
    right: -10px;
  }
  .login-form::v-deep {
    #fileInput {
      display: none !important;
    }
    img {
      border: 1px solid #E2E8F0;
      border-radius: 0.5rem;
      height: 8rem;
    }
  }
  .add-quest-button ::v-deep {
    .button-base {
      text-align: -moz-center;
      text-align: -webkit-center;
      .page-button-real {
        width: 100%;
      }
    }
  }
  .svg-fill {
    fill: transparent;
  }
  .ipt {
    background-color: $light_gray3;
    color: black;
  }
  .login-button::v-deep {
    .button-base {
      text-align: -moz-right;
      text-align: -webkit-right;
    }
  }
</style>
