<template>
  <div class="quiz-list">
    <div class="begin">
      <div class="debt border rounded-2xl p-4 mb-4 cursor-pointer">
        <div class="flex items-center justify-end" @click.stop="$emit('openConfirmation', quizList.quiz.id)">
          <svg-icon
            name="ic_delete-red"
            original
            class="w-5 h-5"
          />
        </div>
        <div class="flex items-center justify-between">
          <div class="part-1 flex items-center">
            <div
              class="image mr-4 h-20 w-20 rounded-lg bg-cover bg-no-repeat"
              v-bind:style="{ 'background-image': 'url(' + quizPicture + ')' }"
            ></div>
            <div class="title text-black text-lg w-3/4 leading-relaxed">{{ quizList.quiz.label }}</div>
          </div>
          <div class="part-2 mr-8">
            <svg-icon
              name="ic_arrow-right"
              original
              class="w-4 h-4"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import http from '../../../plugins/http'
import apiRoute from '../../../router/api-routes'
export default {
  name: 'quiz-list',
  props: {
    quizList: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  data () {
    return {
      quizPicture: ''
    }
  },
  created () {
    this.getQuizPicture()
  },
  methods: {
    getQuizPicture () {
      http.get(apiRoute.baseURL + apiRoute.getFileById + this.quizList.quiz.pictureId, {
        headers: {
          Authorization: `Bearer ${this.$store.getters.loginToken}`
        }
      }).then(response => {
        // console.log(response)
        this.quizPicture = response.file
      }).catch(error => {
        console.log(error)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "../../../assets/style/sass/variables";
</style>
