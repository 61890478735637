<template>
  <div class="is-quiz-question">
    <div class="begin">
      <div class="question mb-6">
        <div class="login-form">
          <div class="label mb-2">{{ $t('question') }}</div>
          <div class="input">
            <label>
              <textarea
                v-model="question"
                rows="5"
                class="ipt p-4 border rounded-lg resize-none w-full text-black"
              ></textarea>
            </label>
          </div>
        </div>
<!--        <div class="type mt-4">
          <div class="login-form">
            <div class="label mb-2">{{ $t('type') }}</div>
            <div class="input relative">
              <label>
                <select
                  v-model="type"
                  class="ipt rounded-lg w-full py-4 pl-4 pr-12 border appearance-none bg-white"
                >
                  <option value="TEXT">TEXT</option>
                  <option value="SELECT">SELECT</option>
                  <option value="RADIO">RADIO</option>
                  <option value="TEXTAREA">TEXTAREA</option>
                  <option value="CHECKBOX">CHECKBOX</option>
                  <option value="IMAGE">IMAGE</option>
                </select>
              </label>
              <div class="absolute top-0 right-0 p-4">
                <svg-icon
                  name="ic_down"
                  original
                  class="w-4 h-4"
                />
              </div>
            </div>
          </div>
        </div>-->
        <div class="response mt-4">
          <div class="title text-black font-medium mb-2">{{ $t('answers') }}</div>
          <div
            v-for="(item, i) in inputs"
            :key="i"
            class="result flex items-center mb-4"
          >
            <div class="choice w-1/12 mr-4">
              <add-answer
                :answer="item"
                @selectAnswer="selectAnswer"
                @click.native="selectRightAnswer(i)"
              />
            </div>
            <div class="input w-11/12">
              <label>
                <input
                  v-model="item.label"
                  type="text"
                  class="ipt w-full p-4 border rounded-lg text-black"
                >
              </label>
            </div>
              <div class="ml-4 cursor-pointer" @click.stop="deleteOneProposition(i)">
                  <svg-icon
                          name="ic_delete-red"
                          original
                          class="w-5 h-5"
                  />
              </div>
          </div>
        </div><br>
        <div
          class="add-ans italic cursor-pointer text-xl font-medium text-green-500"
          @click="add"
        >
          {{ $t('addResponse') }}
        </div><br>
        <div v-if="error" class="error text-red-600">
          {{ errorMessage }}
        </div><br>
        <div class="add-quiz-question">
          <button-base
            :label="$t('saveButton')"
            :is-loading="loader"
            @click.native="saveQuestion"
          />
        </div>
      </div>
    </div>
    <!-- SUCCESS ADD POPUP -->
    <success-add
      v-if="successAdd"
      :message="$t('newQuestionSuccess')"
      :button-label="$t('goBackAddMemberButton')"
      @closeSuccess="closeSuccess"
    />
<!--      <div class="invisible">{{ updateData }}</div>-->
  </div>
</template>

<script>
import AddAnswer from './add-answer'
import ButtonBase from '../add/button-base'
import http from '../../../plugins/http'
import apiRoute from '../../../router/api-routes'
import SuccessAdd from '../success-add'
export default {
  name: 'is-quiz-question',
  components: { SuccessAdd, ButtonBase, AddAnswer },
  props: {
    quizId: Number,
    questionData: Object
  },
  data () {
    return {
      successAdd: false,
      loader: false,
      question: '',
      answer: '',
      type: '',
      error: false,
      errorMessage: '',
      questionId: null,
      selectedAnswer: null,
      inputs: [{
        label: '',
        isAnswers: null,
        id: null
      }]
    }
  },
  created () {
    if (this.questionData) {
      this.inputs = [{
        label: '',
        isAnswers: null,
        id: null
      }]
      console.log(this.questionData)
      this.question = this.questionData.question.label
      while (this.inputs.length < this.questionData.proposition.length) {
        // Dupliquer le premier objet du tableau 2 pour maintenir la longueur
        this.inputs.push({ ...this.inputs[0] })
      }

      // Itérer sur le premier tableau
      this.questionData.proposition.forEach((objet1, index) => {
        // Mettre à jour la valeur du deuxième tableau
        this.inputs[index].id = objet1.id
        this.inputs[index].label = objet1.label
        this.inputs[index].isAnswers = objet1.isValid
      })

      console.log(this.inputs)
    }
  },
  watch: {
    // Utiliser un watcher pour mettre à jour les données internes lorsque les props changent
    questionData (newVal) {
      this.inputs = [{
        label: '',
        isAnswers: null,
        id: null
      }]
      this.question = newVal.question.label
      while (this.inputs.length < newVal.proposition.length) {
        // Dupliquer le premier objet du tableau 2 pour maintenir la longueur
        this.inputs.push({ ...this.inputs[0] })
      }

      // Itérer sur le premier tableau
      newVal.proposition.forEach((objet1, index) => {
        // Mettre à jour la valeur du deuxième tableau
        this.inputs[index].id = objet1.id
        this.inputs[index].label = objet1.label
        this.inputs[index].isAnswers = objet1.isValid
      })
    }
  },
  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    updateData () {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.inputs = [{
        label: '',
        isAnswers: null,
        id: null
      }]
      if (this.questionData) {
        console.log(this.questionData)
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.question = this.questionData.question.label
        while (this.inputs.length < this.questionData.proposition.length) {
          // Dupliquer le premier objet du tableau 2 pour maintenir la longueur
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.inputs.push({ ...this.inputs[0] })
        }

        // Itérer sur le premier tableau
        this.questionData.proposition.forEach((objet1, index) => {
          // Mettre à jour la valeur du deuxième tableau
          this.inputs[index].id = objet1.id
          this.inputs[index].label = objet1.label
          this.inputs[index].isAnswers = objet1.isValid
        })

        console.log(this.inputs)
        return this.inputs
      }
    }
  },
  methods: {
    deleteOneProposition (index) {
      http.delete(apiRoute.baseURL + apiRoute.deleteProposition + this.inputs[index].id, {
        headers: {
          Authorization: `Bearer ${this.$store.getters.loginToken}`
        }
      }).then(response => {
        console.log(response)
        this.inputs.splice(index, 1)
        this.$emit('deletePropositionSuccess')
      }).catch(error => {
        console.log(error)
      })
    },
    async saveQuestion () { // ADD NEW QUESTION AND ANSWERS PROPOSITION
      if (this.question === '') {
        this.error = true
        this.errorMessage = this.$t('errorAddAdmin')
      } else {
        this.loader = true
        this.error = false
        if (this.questionData) {
          console.log('ok')
          http.patch(apiRoute.baseURL + apiRoute.updateQuestion, {
            label: this.question,
            type: 'TEXT',
            quizId: this.questionData.question.quizId,
            id: this.questionData.question.id
          }, {
            headers: {
              Authorization: `Bearer ${this.$store.getters.loginToken}`
            }
          }).then(async response => {
            console.log(response)
            this.questionId = response.id
            for (const objet of this.inputs) {
              if (objet.isAnswers === null) {
                this.loader = false
                this.error = true
                this.errorMessage = 'Veuillez choisir des propositions de réponses'
              } else {
                if (objet.id) { // Update existing question
                  console.log('non vide', objet)
                  await http.patch(apiRoute.baseURL + apiRoute.updateProposition, {
                    label: objet.label,
                    questionId: this.questionData.question.id,
                    isValid: objet.isAnswers,
                    id: objet.id
                  }, {
                    headers: {
                      Authorization: `Bearer ${this.$store.getters.loginToken}`
                    }
                  }).then(response => {
                    console.log(response)
                    this.loader = false
                    this.inputs = [{
                      label: '',
                      isAnswers: null
                    }]
                    // this.successAdd = true
                    // this.$emit('showAddQuizSuccess', true)
                  }).catch(error => {
                    console.log(error)
                  })
                } else {
                  console.log('vide', objet)
                  await http.post(apiRoute.baseURL + apiRoute.createProposition, {
                    label: objet.label,
                    questionId: this.questionData.question.id,
                    isValid: objet.isAnswers
                  }, {
                    headers: {
                      Authorization: `Bearer ${this.$store.getters.loginToken}`
                    }
                  }).then(response => {
                    console.log(response)
                    this.loader = false
                    this.inputs = [{
                      label: '',
                      isAnswers: null
                    }]
                    // this.successAdd = true
                    // this.$emit('showAddQuizSuccess', true)
                  }).catch(error => {
                    console.log(error)
                  })
                }
              }
              await this.$emit('showAddQuizSuccess', true)
            }
          }).catch(error => {
            console.log(error)
          })
        } else {
          const toutesSontBool = this.inputs.every(objet => typeof objet.isAnswers === 'boolean' && objet.isAnswers !== null)
          if (toutesSontBool) {
            // console.log('ok')
            const newOrder = this.$store.getters.questionOrder + 1
            http.post(apiRoute.baseURL + apiRoute.createQuestion, {
              label: this.question,
              type: 'TEXT',
              order: newOrder,
              quizId: this.quizId
            }, {
              headers: {
                Authorization: `Bearer ${this.$store.getters.loginToken}`
              }
            }).then(response => {
              console.log(response)
              this.questionId = response.id
              for (let i = 0; i < this.inputs.length; i++) {
                if (this.inputs[i].isAnswers === null) {
                  this.loader = false
                  this.error = true
                  this.errorMessage = 'Veuillez choisir des propositions de réponses'
                } else {
                  http.post(apiRoute.baseURL + apiRoute.createProposition, {
                    label: this.inputs[i].label,
                    questionId: this.questionId,
                    isValid: this.inputs[i].isAnswers
                  }, {
                    headers: {
                      Authorization: `Bearer ${this.$store.getters.loginToken}`
                    }
                  }).then(response => {
                    console.log(response)
                    this.loader = false
                    this.inputs = [{
                      label: '',
                      isAnswers: null
                    }]
                    // this.successAdd = true
                    this.$emit('showAddQuizSuccess', true)
                  }).catch(error => {
                    console.log(error)
                  })
                }
              }
              this.$store.dispatch('CREATE_ORDER', newOrder)
            }).catch(error => {
              console.log(error)
            })
          } else {
            this.loader = false
            this.error = true
            this.errorMessage = 'Veuillez choisir des propositions de réponses'
          }
        }
      }
    },
    closeSuccess (val) { // CLOSE SUCCESS POPUP
      this.$emit('closeUpdateQuestion', false)
      this.successAdd = val
      // window.location.reload()
    },
    add () { // ADD NEW QUESTION AND ANSWER
      this.inputs.push({
        label: '',
        isAnswers: null
      })
    },
    selectRightAnswer (index) { // SELECT THE RIGHT ANSWER
      this.inputs[index].isAnswers = this.selectedAnswer
    },
    selectAnswer (val) {
      this.selectedAnswer = val
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "../../../assets/style/sass/variables";
</style>
