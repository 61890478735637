<template>
  <div class="add-answer">
    <div class="begin">
      <div class="flex items-center">
        <div class="icons flex items-center">
          <div class="is-true mr-4 cursor-pointer">
            <svg-icon
              name="ic_select-answer"
              original
              class="w-6 h-6"
              :class="{ 'selected_right': position === 1 }"
              @click="isPosition(1)"
            />
          </div>
          <div class="is-false cursor-pointer">
            <svg-icon
              name="ic_select-not-answer"
              original
              class="w-6 h-6"
              :class="{ 'selected_wrong': position === 2 }"
              @click="isPosition(2)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'add-answer',
  props: {
    answer: Object
  },
  data () {
    return {
      position: -1
    }
  },
  created () {
    if (this.answer) {
      if (this.answer.isAnswers === true) {
        this.position = 1
      } else if (this.answer.isAnswers === false) {
        this.position = 2
      }
    }
  },
  methods: {
    isPosition (key) {
      this.position = key
      if (this.position === 1) {
        this.$emit('selectAnswer', true)
      } else if (this.position === 2) {
        this.$emit('selectAnswer', false)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "../../../assets/style/sass/variables";
  .selected_right::v-deep {
    path {
      fill: #00A958;
    }
  }
  .selected_wrong::v-deep {
    path {
      fill: #DE1D1D;
    }
  }
</style>
